.home-page {
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23cfe5ff' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");

	.app {
		width: 100%;
		height: calc(100vh - 116.94px);
		@include centering;
		padding: 2rem;

		.droparea-container {
			width: 100%;
			height: 100%;
			max-width: 56rem;
			max-height: 46rem;
			@include fadeIn;
			animation-delay: 300ms;
			position: relative;

			.filepond--wrapper {
				width: 100%;
				height: 100%;

				.filepond--credits,
				.filepond--assistant {
					display: none;
				}

				.filepond--drop-label {
					transform: unset !important;

					label {
						font-size: 2.4rem;
						color: var(--dark-blue-x3);
						@include centering;
						flex-direction: column;

						span {
							color: var(--light-blue-x1);
							text-rendering: optimizeLegibility;
						}
						span:nth-of-type(1) {
							span {
								text-transform: uppercase;
							}
						}

						span:nth-of-type(2) {
							color: var(--dark-blue-x1);
							margin: 1rem 0 0.5rem 0;
							font-size: 2.2rem;
						}

						span:nth-of-type(3) {
							transition: color 250ms ease-in-out, text-decoration-color 250ms ease-in-out;
							text-decoration-color: unset;
							text-decoration-line: underline;
							text-decoration-thickness: 3px;
							text-decoration-color: var(--handle-bg);
							color: var(--handle-bg);
							cursor: pointer;
							font-size: 2.8rem;

							&:hover,
							&:active {
								color: var(--purple);
								text-decoration-color: var(--purple);
							}
						}
					}
				}

				.filepond--panel-root {
					background: var(--light-blue-x2);
					border-radius: $borderAnt !important;
					border: 2px dashed var(--light-blue-x1);
					transition: border-color 250ms ease-in-out;
				}

				.filepond--list-scroller {
					.filepond--list {
						.filepond--item {
							width: 100%;
							height: 100% !important;

							.filepond--file-wrapper {
								padding: 4.4rem 2.4rem;
								.filepond--file {
									position: relative;

									button {
										font-size: 2.2rem;
										background: var(--light-blue-x1);
										cursor: pointer;
									}

									.filepond--action-abort-item-load,
									.filepond--action-retry-item-load,
									.filepond--action-abort-item-processing,
									.filepond--action-revert-item-processing,
									.filepond--action-process-item {
										display: none;
									}

									.filepond--processing-complete-indicator {
										top: 50% !important;
										left: 50% !important;
										transform: translate(-50%, -50%) !important;
										width: 15rem;
										height: 15rem;

										svg {
											width: 100%;
											height: 100%;
											color: var(--purple);
										}
									}

									.filepond--file-info {
										transform: unset !important;
										max-width: calc(100% - 8rem) !important;

										.filepond--file-info-main,
										.filepond--file-info-sub {
											width: max-content !important;
											font-size: 1.7rem;
											color: var(--dark-blue-x3);
										}

										.filepond--file-info-main {
											width: 100% !important;
										}
									}
									.filepond--file-status {
										padding-top: 4px;
										span {
											font-size: 2rem;
											color: red;
										}
										.filepond--file-status-sub {
											display: none;
										}
									}
									.filepond--process-indicator {
										width: 70px;
										height: 70px;
										top: 52% !important;
										left: 50% !important;
										transform: translate(-52%, -50%);
										svg {
											fill: var(--purple);
											width: 100% !important;
											height: 100% !important;

											path {
												stroke: var(--purple);
											}
										}
									}

									.filepond--action-retry-item-processing,
									.filepond--action-remove-item {
										cursor: pointer;
										transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
										bottom: 0 !important;

										&:hover {
											background-color: var(--purple);
											box-shadow: 0px 0px 6px 0px rgba($color: #000000, $alpha: 0.6);
										}
									}

									.filepond--action-retry-item-processing {
										right: 0.8rem !important;
									}
									.filepond--action-remove-item {
										left: 0.8rem !important;
									}
								}
							}
						}
					}
				}

				&:hover,
				&:active {
					.filepond--panel-root {
						border-color: var(--purple) !important;
					}
				}
			}

			// .clear-btn {
			// 	padding: 1rem;
			// 	@include centering;
			// 	position: absolute;
			// 	top: 0;
			// 	right: 0;
			// 	z-index: 999;
			// 	border-radius: 0 2.4rem 0 1.6rem;
			// 	background-color: var(--light-blue-x1);
			// 	transition: background-color 250ms ease-in-out;
			// 	cursor: pointer;

			// 	.icon {
			// 		color: var(--white);
			// 		font-size: 2.4rem;
			// 	}

			// 	&:hover,
			// 	&:active {
			// 		background-color: var(--purple);
			// 	}
			// }
		}
	}
}

@media (max-width: 650px) {
	.home-page {
		.app {
			.droparea-container {
				.filepond--wrapper {
					.filepond--list-scroller {
						.filepond--list {
							.filepond--item {
								.filepond--file-wrapper {
									.filepond--file {
										.filepond--file-status {
											margin-right: 4rem;
											padding-top: 4px;
											span {
												font-size: 1.2rem;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
