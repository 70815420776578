.loading-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	@include fadeIn;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.9);
	@include fadeIn;

	.wrapper {
		margin: 0 0 1.6rem 3rem;
		@include centering;
		gap: 1rem;

		svg {
			background: transparent;
			display: block;
			shape-rendering: auto;
			width: 8rem;
			height: auto;
			overflow: visible;
			margin: 0;
			animation: rotating 2s linear infinite;
			@keyframes rotating {
				from {
					-ms-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-webkit-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
				}
				to {
					-ms-transform: rotate(360deg);
					-moz-transform: rotate(360deg);
					-webkit-transform: rotate(360deg);
					-o-transform: rotate(360deg);
					transform: rotate(360deg);
				}
			}
		}

		h1 {
			font-size: 2.4rem;
			color: var(--handle-bg);
			will-change: opacity;
			animation: waiting 3s ease-in-out infinite;

			@keyframes waiting {
				50% {
					opacity: 0.4;
				}
				100% {
					opacity: 1;
				}
			}
		}
	}
}
