@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");

html,
body,
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

ul,
ol,
li {
	margin: 0;
	padding: 0;
	list-style: none;
	text-indent: 0;
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	font-size: 62.5%;
}

a,
Link {
	text-decoration: none;
	color: inherit;
	font: inherit;
}

body {
	background: var(--white);
	min-height: 100vh;
	width: 100vw;
	user-select: none;
	overflow-x: hidden;
	font-family: "Lato", sans-serif;
	font-style: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;

	&::-webkit-scrollbar {
		width: 0;
	}
}

div,
input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--dark-blue-x3);
	margin: 0;
}

p {
	font-weight: 400 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700 !important;
}

button {
	font: unset;
	font-family: inherit;
	border: 0;
	background: unset;
	&:focus {
		outline: 0;
	}
}

label,
textarea,
input {
	color: #000;
	width: 100%;

	&:focus {
		outline: 0;
	}
	font-size: 1.6rem;
	line-height: 2.4rem;
	&::placeholder {
		color: rgba($color: var(--white), $alpha: 0.6);
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s;
		-webkit-text-fill-color: var(--white) !important;
	}
}
