.notification-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 9999999;

	.notification-card {
		position: absolute;
		top: 3rem;
		right: 3rem;
		animation: slidingIn 250ms ease-in forwards;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
		border-radius: 12px 12px 0 12px;
		width: 40rem;
		height: auto;
		cursor: default;
		z-index: 999999999;
		pointer-events: all;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.notification-content {
			width: 100%;
			padding: 1.2rem 0 1.2rem 1.2rem;
			color: var(--white);

			.error-icon {
				font-size: 2rem;
				margin: 0 0.5rem -0.5rem 0;
				color: inherit;
			}

			p {
				display: inline;
				font-size: 1.6rem;
				color: inherit;
				line-height: 2rem;
			}
		}

		.notification-close {
			padding: 0.8rem;
			cursor: pointer;
			transition: transform 250ms ease-in-out;
			will-change: transform;
			.icon {
				font-size: 2rem;
				color: var(--white);
				color: #000;
			}

			&:hover,
			&:active {
				transform: scale(1.08);
			}
		}

		@keyframes slidingIn {
			0% {
				transform: translateY(-25px);
				opacity: 0;
			}
			100% {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
.notification-container-close {
	.notification-card {
		animation: slidingOut 250ms ease-in-out forwards;
		@keyframes slidingOut {
			0% {
				transform: translateY(0);
				opacity: 1;
			}
			100% {
				transform: translateY(-25px);
				opacity: 0;
			}
		}
	}
}

.notification-container.error {
	background-color: rgba($color: #000000, $alpha: 0.4);
	transition: background-color 250ms ease-in-out;
	.notification-card {
		border: 1px solid #590404;
		background-color: rgb(201, 13, 13) !important;
	}
}
.notification-container-close.error {
	animation: bgFade 250ms ease-in-out forwards 100ms;
	@keyframes bgFade {
		100% {
			background: rgba($color: #000000, $alpha: 0);
		}
	}
}

.notification-container.success {
	.notification-card {
		background: #2bbf0d;
		border-color: #07661a;
	}
}

@media screen and (max-width: 768px) {
	.notification-container {
		.notification-card {
			top: 1.6rem;
			right: 1.6rem;
			padding: 1.4rem;
			width: 30rem;

			p {
				font-size: 2.1rem;
			}
		}
	}
}
