header {
	width: 100%;
	padding: 16px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.left,
	.right {
		display: flex;
		align-items: center;
	}

	.left {
		justify-content: flex-start;
		@include slideFromLeft;
		gap: 24px;

		img {
			width: 3.8rem;
			height: auto;
		}

		h1 {
			font-size: 1.8rem;
			color: var(--dark-blue-x1);
			font-weight: 700;
			text-align: center;
		}
	}
}

.home-page {
	.left {
		h1 {
			text-align: left;
		}
	}

	.right {
		justify-content: flex-end;
		gap: 2.4rem;
	}
}

@media (max-width: 950px) {
	.home-page {
		header {
			padding: 14px 16px;
			.left {
				gap: 16px;
				img {
					width: 3rem;
				}

				h1 {
					font-size: 1.5rem;
					max-width: 100%;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.home-page {
		header {
			.left {
				gap: 10px;
				img {
					width: 2.4rem;
				}

				h1 {
					font-size: 1.4rem;
				}
			}
			.right {
				gap: 1.4rem;
			}
		}
	}
}

@media (max-width: 610px) {
	header {
		.left {
			gap: 14px;
			img {
				width: 3rem;
				height: auto;
			}

			h1 {
				font-size: 1.4rem;
				color: var(--dark-blue-x1);
				font-weight: 700;
				text-align: center;
				max-width: 60%;
			}
		}

		.right {
			padding: 0.6rem 1rem;
			font-size: 1.2rem;
		}
	}

	.home-page {
		header {
			.left {
				h1 {
					font-size: 1.2rem;
				}
			}
		}
	}
}

.home-page {
	header {
		background: rgba($color: #e5f1ff, $alpha: 0.9);
	}
}

.auth-page {
	header {
		background: transparent;
		padding: 0;
		position: relative;

		.left {
			position: absolute;
			top: 1.2rem;
			left: 1.2rem;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			height: calc(100vh - 46.94px);

			h1 {
				font-weight: 400;
				color: var(--white);
				padding-left: 1.2rem;
				writing-mode: vertical-rl;
				text-orientation: upright;
				font-size: 1.4rem;
				height: calc(100vh - 46.94px);
			}

			img {
				width: 14rem;
				height: auto;
			}
		}
	}
}

@media screen and (max-height: 540px) {
	.auth-page {
		header {
			.left {
				h1 {
					display: none;
				}

				img {
					width: 12rem;
				}
			}
		}
	}
}

@media (max-width: 400px) {
	.auth-page {
		header {
			.left {
				h1 {
					display: none;
				}
			}
		}

		.footer-bottom {
			padding: 1rem;
			p,
			a {
				font-size: 1.1rem;
			}
		}
	}
}
