.toggle-btn {
	@include centering;
	gap: 1.2rem;

	.toggle-label {
		font-size: 1.6rem;
		will-change: transform;
		transition: transform 250ms ease-in-out, font-weight 250ms ease-in-out, color 250ms ease-in-out;
		color: var(--light-grey);
		font-weight: 400;
		cursor: pointer;

		&:nth-of-type(1) {
			width: 66.45px;
		}
		&:nth-of-type(2) {
			width: 74.81px;
		}

		&:hover,
		&active {
			color: var(--dark-blue-x1);
		}
		&:active {
			transform: scale(0.98);
		}
	}
	.toggle-label.checked {
		font-weight: 700;
		color: var(--dark-blue);
		cursor: default;
	}

	label {
		display: inline-block;
		width: 50px;
		height: 24px;
		position: relative;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		.slider {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 30px;
			box-shadow: 0 0 0 2px #777, 0 0 4px #777;
			cursor: pointer;
			border: 3px solid transparent;
			overflow: hidden;
			transition: 0.2s;

			&:before {
				position: absolute;
				content: "";
				width: 100%;
				height: 100%;
				background-color: #777;
				border-radius: 30px;
				transform: translateX(-25px);
				transition: 0.2s;
			}
		}

		input:checked + .slider:before {
			transform: translateX(25px);
			background-color: var(--dark-blue);
		}

		input:checked + .slider {
			box-shadow: 0 0 0 2px var(--dark-blue), 0 0 8px var(--dark-blue);
		}
	}
}

@media (max-width: 800px) {
	.toggle-btn {
		gap: 0.8rem;

		.toggle-label {
			font-size: 1.5rem;

			&:nth-of-type(1) {
				width: 62.3px;
			}
			&:nth-of-type(2) {
				width: 70.14px;
			}
		}

		label {
			width: 46px;
			height: 22px;

			.slider {
				border-width: 2px;

				&:before {
					transform: translateX(-23px);
				}
			}

			input:checked + .slider:before {
				transform: translateX(23px);
			}
		}
	}
}
