$borderAnt: 24px 24px 0 24px;

@import "./resets.scss";
@import "./mixins.scss";
//comps
@import "comps/layout/header";
@import "comps/layout/footer";
@import "comps/notification";
@import "comps/loading";
@import "comps/dropdown";
@import "comps/toggle";
//PAGES
@import "pages/home";
@import "pages/auth";
@import "pages/notFound";

:root {
	--nav-pressed: #fff;

	--purple: #c31dbd;
	--handle-bg: #9ebfe5;

	--light-blue-x1: #7f9bbb;
	--light-blue-x2: #e5f1ff;
	--light-blue-x3: #f2f8ff;
	--white: #ffffff;

	--light-grey: #8e8e8e;
	--dark-blue: #2472cb;
	--dark-blue-x1: #003878;
	--dark-blue-x3: #200041;
}

body {
	width: 100vw;
	height: 100vh;

	.page {
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
