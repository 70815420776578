.notfound-page {
	@include centering;
	width: 100%;
	height: 100%;
	padding: 0 2rem;

	svg {
		width: 100%;
		max-width: 450px;
		height: calc(100vh - 46.94px);
	}
}
