footer {
	width: 100%;
	@include centering;
	padding: 1.6rem 0;
	@include slideFromBottom;

	p,
	a {
		font-size: 1.3rem;
		color: var(--light-blue-x1);
	}

	p:nth-of-type(1) {
		margin-right: 4px;
	}

	.footer-link {
		font-weight: 700;
		transition: color 250ms ease-in-out;
		&:hover,
		&:active {
			color: var(--purple);
		}
	}
}

.auth-page {
	footer {
		.footer-link {
			font-weight: 700;
			transition: color 250ms ease-in-out;
			&:hover,
			&:active {
				color: var(--white);
			}
		}
	}
}
