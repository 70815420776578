.dropdown {
	position: relative;
	@include slideFromRight;

	.dropdown-btn {
		box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.1);
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 120px;
		font-size: 1.7rem;
		color: var(--dark-blue-x1);
		border-radius: 8px;
		transition: all 250ms ease-in-out;
		gap: 10px;
		padding: 0.8rem 0;

		h2 {
			font-size: inherit;
			color: inherit;
		}
	}
	.dropdown-content {
		width: 180px;
		background-color: var(--light-blue-x2);
		border-radius: 8px 8px 0 8px;
		position: absolute;
		bottom: -134px;
		right: 0;
		box-shadow: 0px 0px 4px 0px rgba($color: #000, $alpha: 0.4);
		overflow: hidden;

		.download-btn,
		.sign-out-btn {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.2rem;
			cursor: pointer;
			font-size: 1.5rem;
			color: var(--dark-blue-x3);
			transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

			p,
			.icon {
				color: inherit;
			}
			&:hover,
			&:active {
				color: var(--purple);
				background-color: var(--light-blue-x3);
			}
		}
	}
	.dropdown-content-enter {
		opacity: 0;
		transform: translateY(-10px);
	}

	.dropdown-content-enter-active {
		opacity: 1;
		transform: translateY(0);
		transition: opacity 300ms, transform 300ms;
	}

	.dropdown-content-exit {
		opacity: 1;
		transform: translateY(0);
	}

	.dropdown-content-exit-active {
		opacity: 0;
		transform: translateY(-10px);
		transition: opacity 300ms, transform 300ms;
	}
}

@media (max-width: 800px) {
	.dropdown {
		.dropdown-btn {
			width: 94px;
			font-size: 1.5rem;
			gap: 8px;
			padding: 0.7rem 0;
		}
		.dropdown-content {
			width: 180px;
			bottom: -132px;
		}
	}
}
