@mixin centering {
	display: flex;
	justify-content: center;
	align-items: center;
}

//ANIMATIONS
@mixin fadeIn {
	opacity: 0;
	animation: fadeIn 220ms ease-in forwards;

	@keyframes fadeIn {
		100% {
			opacity: 1;
		}
	}
}
@mixin slideFromLeft {
	opacity: 0;
	transform: translateX(-45px);
	animation: slideLeft 400ms ease-in forwards;

	@keyframes slideLeft {
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}
@mixin slideFromRight {
	opacity: 0;
	transform: translateX(45px);
	animation: slideRight 400ms ease-in forwards;

	@keyframes slideRight {
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}
@mixin slideFromBottom {
	opacity: 0;
	transform: translateY(45px);
	animation: slideTop 400ms ease-in forwards;

	@keyframes slideTop {
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
