.auth-page {
	background-color: #003878;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%230E4878' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23295A78'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
	@include centering;

	.wrapper {
		width: 100%;
		max-width: 900px;
		height: 90%;
		max-height: 972px;
		padding: 4rem;
		display: flex;
		flex-direction: column;

		.top {
			@include centering;
			flex-direction: column;
			flex: 1;

			.icon,
			h1 {
				color: var(--white);
			}

			.icon {
				font-size: 13rem;
			}
			h1 {
				font-size: 4.8rem;
				text-transform: uppercase;
			}
		}
		form {
			padding: 4rem 0;
			flex: 4;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			gap: 6rem;

			.form-rows {
				width: 100%;
				@include centering;
				flex-direction: column;
				gap: 3.2rem;
				flex: 4;

				.row {
					width: 100%;
					@include centering;
					flex-direction: column;
					gap: 1rem;

					h2 {
						font-size: 2.6rem;
						color: var(--white);
					}

					.input-bar-icon {
						width: 100%;
						max-width: 360px;
						height: 48px;
						padding: 0.4rem 1.2rem;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						transition: all 250ms ease-in-out;
						border: 1px solid var(--light-blue-x1);
						font-size: 1.8rem;

						.icon {
							color: var(--light-blue-x1);
							margin-right: 1rem;
							font-size: 2.4rem;
						}
						input {
							border: 0;
							background: none;
							font-size: inherit;
							color: var(--light-blue-x1);
							width: 100%;
							color: var(--white);
							line-height: 2.4rem;
							text-rendering: optimizeLegibility;

							&::placeholder {
								color: var(--light-blue-x1);
							}

							&:-webkit-autofill,
							&:-webkit-autofill:hover,
							&:-webkit-autofill:focus,
							&:-webkit-autofill:active {
								-webkit-box-shadow: 0 0 0 30px transparent inset !important;
							}
						}
						&:focus-within {
							border-color: var(--white);
							.icon {
								color: var(--white);
							}

							input {
								color: var(--white);
							}
						}
					}
					.input-bar-icon.filled {
						border-color: var(--white);
						.icon {
							color: var(--white);
						}

						input {
							color: var(--white);
						}
					}
				}

				.email-row {
					.input-bar-icon {
						border-radius: $borderAnt;
					}
				}
				.pass-row {
					.input-bar-icon {
						border-radius: 0 24px 24px 24px;

						.show-pass-btn {
							padding: 1rem 1rem 1rem 0;
							cursor: pointer;
							.icon {
								margin: 0;
							}
						}
					}
				}
			}

			.submit-btn {
				flex: 1;
				cursor: pointer;
				.icon {
					font-size: 5rem;
					color: var(--light-blue-x1);
					transition: color 250ms ease-in-out;
				}

				&:hover,
				&:active {
					.icon {
						color: var(--white);
					}
				}
			}
		}
	}
}

@media (max-height: 884px) {
	.auth-page {
		.wrapper {
			height: 100%;
			flex-direction: row;
			justify-content: space-evenly;
			max-width: 1150px;

			.top {
				@include centering;
				flex-direction: column;
				flex: 2;

				.icon {
					font-size: 10rem;
				}
				h1 {
					font-size: 3.8rem;
				}
			}
		}
	}
}

@media (max-width: 980px) and (max-height: 884px) {
	.auth-page {
		.wrapper {
			align-items: center;
			.top {
				.icon {
					font-size: 8rem;
				}
				h1 {
					font-size: 2.8rem;
				}
			}

			form {
				padding: 2rem 0;
				gap: 8rem;

				.form-rows {
					gap: 3.4rem;
					flex: 3;

					.row {
						gap: 1rem;

						h2 {
							font-size: 2.2rem;
						}

						.input-bar-icon {
							max-width: 300px;
							height: 42px;
							padding: 0.4rem 1rem;
							.icon {
								margin-right: 0.8rem;
								font-size: 1.8rem;
							}
							input {
								font-size: 1.8rem;
							}
						}
					}

					.pass-row {
						.input-bar-icon {
							.show-pass-btn {
								padding: 0.9rem 0.9rem 0.9rem 0;
							}
						}
					}
				}

				.submit-btn {
					flex: 1;
					.icon {
						font-size: 4rem;
					}
				}
			}
		}
	}
}

@media (max-width: 740px) {
	.auth-page {
		.wrapper {
			flex-direction: column;
			justify-content: center;
			.top {
				.icon {
					font-size: 6rem;
				}
				h1 {
					font-size: 2.4rem;
				}
			}
			form {
				padding: 1.2rem 0;
				gap: 4rem;

				.form-rows {
					gap: 2rem;
					flex: 2;

					.row {
						gap: 0.8rem;

						h2 {
							font-size: 1.8rem;
						}

						.input-bar-icon {
							max-width: 280px;
							height: 36px;
						}
					}
				}

				.submit-btn {
					flex: 1;
					.icon {
						font-size: 3.6rem;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 540px) {
	.auth-page {
		.wrapper {
			.top {
				.icon {
					font-size: 4rem;
				}
				h1 {
					font-size: 2rem;
				}
			}
		}
	}
}

@media screen and (max-height: 500px), (max-width: 380px) {
	.auth-page {
		.wrapper {
			form {
				gap: 3rem;

				.form-rows {
					.row {
						h2 {
							font-size: 1.6rem;
						}

						.input-bar-icon {
							height: 32px;
							max-width: 270px;
							padding: 0.4rem 0.8rem;

							.icon {
								font-size: 1.7rem;
							}

							input {
								font-size: 1.5rem;
							}
						}
					}
				}
			}
		}
	}
}
